import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircleFlag } from 'react-circle-flags';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const languages = [
    { code: 'el', flag: 'gr' },
    { code: 'en', flag: 'gb' },
    { code: 'de', flag: 'de' },
  ];

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="flex space-x-6 mt-4 justify-center">
      {languages.map((language) => (
        <button
          key={language.code}
          onClick={() => changeLanguage(language.code)}
          className="flex justify-center items-center w-8 h-8 bg-white rounded-full hover:bg-gray-300 transition-opacity duration-300 hover:opacity-70"
        >
          <CircleFlag countryCode={language.flag} className="w-8 h-8" />
        </button>
      ))}
    </div>
  );
};

export default LanguageSelector;
