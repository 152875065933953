import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    "nav": {
                        "home": "Home",
                        "boats": "Boats",
                        "destinations": "Destinations",
                        "faq": "FAQ",
                        "contact": "Contact",
                    },
                    "home": {
                        "welcome_message": "Discover Pagasetikos with Us!",
                        "welcome_description": "Whether you're looking for a relaxing day on the water or an adventure-filled excursion, we have the perfect vessel for you. Our fleet is well-maintained and equipped to ensure your safety and comfort throughout your voyage. Choose us for an unforgettable boating experience!",
                    },
                    "boats": {
                        "heading": "Discover our Boats",
                        "subheading": "What Sets Us Apart",
                        "info_box": {
                            "length": "Length",
                            "people_capacity": "People Capacity",
                            "horsepower": "Horsepower",
                            "licence_required": "Licence Required"
                        },
                        "marios": {
                            "name": "Marios",
                            "length": "4.5m",
                            "people_capacity": "6",
                            "horsepower": "30-40 HP",
                            "license_required": "No",
                        },
                        "thanasis": {
                            "name": "Thanasis",
                            "length": "5m",
                            "people_capacity": "7",
                            "horsepower": "30-50 HP",
                            "license_required": "No",
                        },
                        "dimitra": {
                            "name": "Dimitra",
                            "length": "4.5m",
                            "people_capacity": "6",
                            "horsepower": "20 HP",
                            "license_required": "No",
                        }
                        // "variety_of_boats": "Wide variety of boats",
                        // "choose_boat": "Choose from a variety of boats to suit your needs",
                        // "fully_equipped": "Fully equipped and well-maintained boats",
                        // "highest_standards": "All our boats are maintained to the highest standards",
                        // "exclusive_destinations": "Exclusive Trip Destinations",
                        // "explore_beauty": "Explore the beauties of Pagasitikos for an unforgettable experience",
                        // "flexible_rentals": "Flexible rental options",
                        // "customizable_packages": "Customizable rental packages to suit your schedule and preferences",
                    },
                    "destinations": {
                        "heading": "Discover the Beauty of the Sea",
                        "description": "Embark on a memorable journey with our top-of-the-line boats around Pteleos, an exceptionally picturesque coastal area located in Magnesia, Greece. With the Aegean Sea as its backdrop and the majestic mountain of Pelion rising enchantingly in the background, visitors to Pteleos enjoy a unique combination of mountain and sea experience. The area boasts a plethora of interesting sites that can be explored by boat and allow you to discover the hidden beaches, natural coves, and remote picturesque shores of the region, offering a unique opportunity to enjoy the Aegean world from an entirely different perspective! With the right equipment visitors will be able to enjoy a safe and enjoyable experience in the crystalline waters of the Aegean, while having the chance to explore the rich marine life and interesting attractions of the area. Discover the magic of Pteleos and the surrounding area!",
                        "captions": {
                            "Pteleos_Castle": {
                                "title": "Pteleos Castle",
                                "description": "Pteleos Castle is an ancient fortified town built on a hill above Pteleos. It offers a spectacular view of Pagasitikos Gulf, Pelion, and the wider area.",
                            },
                            "Pigadi": {
                                "title": "Pigadi",
                                "description": "Pigadi is a traditional village with rich natural beauty and scenic architecture. Visitors can enjoy the tranquility of the landscape and taste the local flavors in the village taverns.",
                            },
                            "Palio_Trikeri": {
                                "title": "Palio Trikeri",
                                "description": "The traditional village of Palio Trikeri is located a short distance from Pteleos and offers a spectacular view of the Aegean Sea. Visitors can browse the picturesque alleys and enjoy the authentic atmosphere of the village.",
                            },
                            "Agia_Kyriaki": {
                                "title": "Agia Kyriaki",
                                "description": "The beach of Agia Kyriaki offers an amazing combination of sandy and rocky landscape, with crystal clear waters that attract water sports and snorkeling enthusiasts.",
                            },
                            "Jasteni": {
                                "title": "Jasteni",
                                "description": "The double cove with the blue-green waters, an area protected by Unesco."
                            }
                        },
                    },
                    "faq": {
                        "nav_heading": "FAQ",
                        "heading": "Frequently Asked Questions",
                        "subheading": "Here are some of the most common questions we receive.",
                        "Q1": "What types of boats do you offer for rental?",
                        "A1": "We offer a variety of boats including speedboats and fishing boats.",
                        "Q2": "Do I need a boating license to rent a boat?",
                        "A2": "In most cases, a boating license is not required for our rentals. However, some restrictions may apply based on local regulations.",
                        "Q3": "How far in advance should I book a boat rental?",
                        "A3": "We recommend booking your boat rental at least a week in advance to ensure availability, especially during peak seasons.",
                        "Q4": "Are life jackets provided with the boat rental?",
                        "A4": "Yes, we provide life jackets for all passengers as safety is our top priority.",
                        "Q5": "What is your cancellation policy?",
                        "A5": "Our cancellation policy varies depending on the rental duration and peak seasons."
                    },
                    "contact": {
                        "heading": "Contact Us",
                        "subheading": "Get in touch",
                        "paragraph": "We would love to hear from you. Please fill out the form or reach out to us using the contact information provided.",
                        "address": {
                            "k": "Address",
                            "v": "Loutro Pteleou 37007, Greece"
                        },
                        "phone": "Contact Phone",
                        "form": {
                            "name": "Full Name",
                            "email": "Email",
                            "subject": "Subject",
                            "message": "Message",
                            "send": "Send",
                            "error": {
                                "mandatory": "This field is mandatory",
                                "invalid_email": "Please enter a valid email address"
                            }
                        }
                    }

                }
            },
            de: {
                translation: {
                    "nav": {
                        "home": "Startseite",
                        "boats": "Boote",
                        "destinations": "Reiseziele",
                        "faq": "Häufige Fragen",
                        "contact": "Kontakt",
                    },
                    "home": {
                        "welcome_message": "Entdecken Sie Pagasitikos mit uns!",
                        "welcome_description": "Mit unseren gut ausgestatteten Booten können Gäste ein sicheres und angenehmes Erlebnis im kristallklaren Wasser der Ägäis genießen und gleichzeitig die reiche Unterwasserwelt und die interessanten Attraktionen der Region erkunden. Entdecken Sie den Zauber von Pteleos und der Umgebung bei einem unvergesslichen Bootserlebnis!",
                    },
                    "boats": {
                        "heading": "Entdecken Sie unsere Boote",
                        "subheading": "Was unterscheidet uns vom Rest?",
                        "info_box": {
                            "length": "Länge",
                            "people_capacity": "Personenkapazität",
                            "horsepower": "Pferdestärken",
                            "licence_required": "Lizenz erforderlich"
                        },
                        "marios": {
                            "name": "Marios",
                            "length": "4.5m",
                            "people_capacity": "6",
                            "horsepower": "30-40 PS",
                            "license_required": "Nein",
                        },
                        "thanasis": {
                            "name": "Thanasis",
                            "length": "5m",
                            "people_capacity": "7",
                            "horsepower": "30-50 PS",
                            "license_required": "Nein",
                        },
                        "dimitra": {
                            "name": "Dimitra",
                            "length": "4.5m",
                            "people_capacity": "6",
                            "horsepower": "20 PS",
                            "license_required": "Nein",
                        }
                        // "variety_of_boats": "Große Auswahl an Booten",
                        // "choose_boat": "Wählen Sie aus einer Vielzahl von Booten das Passende für Ihre Bedürfnisse",
                        // "fully_equipped": "Voll ausgestattete und gut gewartete Boote",
                        // "highest_standards": "Alle unsere Boote werden nach den höchsten Standards gewartet",
                        // "exclusive_destinations": "Exklusive Ausflugsziele",
                        // "explore_beauty": "Entdecken Sie die Schönheiten von Pagasitikos für ein unvergessliches Erlebnis",
                        // "flexible_rentals": "Flexible Mietoptionen",
                        // "customizable_packages": "Anpassbare Mietpakete, die Ihrem Zeitplan und Ihren Vorlieben entsprechen",
                    },
                    "destinations": {
                        "heading": "Entdecken Sie die Schönheit des Meeres",
                        "description": "Begeben Sie sich mit unseren Top-Booten auf eine unvergessliche Reise rund um Pteleos, einem äußerst malerischen Küstengebiet von Magnesia. Mit der Ägäis im Hintergrund und dem majestätischen Berg Pilion im Hintergrund genießen Besucher von Pteleos eine einzigartige Kombination aus Berg- und Meereserlebnis. Die Gegend verfügt über eine Fülle interessanter Sehenswürdigkeiten, die mit dem Boot erkundet werden können. Mit den gemieteten Booten können Sie die versteckten Strände, natürlichen Buchten und einsamen malerischen Küsten der Region entdecken und bieten so die einmalige Gelegenheit, die Welt der Ägäis aus einer ganz anderen Perspektive zu genießen. Mit der richtigen Ausrüstung genießen Sie ein sicheres und angenehmes Erlebnis im kristallklaren Wasser der Ägäis und haben gleichzeitig die Möglichkeit, das reiche Meeresleben und interessante Sehenswürdigkeiten zu erkunden. Entdecken Sie den Zauber von Pteleos und Umgebung!",
                        "captions": {
                            "Pteleos_Castle": {
                                "title": "Burg Pteleos",
                                "description": "Die Burg Pteleos ist eine alte befestigte Stadt, die auf einem Hügel oberhalb von Pteleos erbaut wurde. Es bietet einen spektakulären Blick auf den Golf von Pagasitikos, Pilion und die weitere Umgebung.",
                            },
                            "Pigadi": {
                                "title": "Pigadi",
                                "description": "Pigadi ist ein wunderschönes traditionelles Dorf mit reicher natürlicher Schönheit und traditioneller Architektur. Besucher können die Ruhe der Landschaft genießen und in den Dorftavernen die lokalen Aromen probieren.",
                            },
                            "Palio_Trikeri": {
                                "title": "Palio Trikeri",
                                "description": "Das traditionelle Dorf Palio Trikeri liegt nicht weit von Pteleos entfernt und bietet einen spektakulären Blick auf die Ägäis. Besucher können durch die malerischen Gassen schlendern und die authentische Atmosphäre des Dorfes genießen.",
                            },
                            "Agia_Kyriaki": {
                                "title": "Agia Kyriaki",
                                "description": "Der Strand von Agia Kyriaki bietet eine erstaunliche Kombination aus Sand- und Felslandschaft mit kristallklarem Wasser, das Wassersportler und Schnorchelbegeisterte anzieht.",
                            },
                            "Jasteni": {
                                "title": "Jasteni",
                                "description": "Die Doppelbucht mit dem blaugrünen Wasser, ein von der Unesco geschütztes Gebiet."
                            }
                        },
                    },
                    "faq": {
                        "nav_heading": "Häufige Fragen",
                        "heading": "Häufige Fragen",
                        "subheading": "Hier sind einige der häufigsten Fragen, die uns gestellt werden.",
                        "Q1": "Welche Bootstypen bieten Sie zur Miete an?",
                        "A1": "Wir bieten eine Vielzahl von Booten an, darunter Schnellboote und Fischerboote.",
                        "Q2": "Brauche ich einen Führerschein, um ein Boot zu mieten?",
                        "A2": "In den meisten Fällen ist für unsere Vermietungen keine Lizenz erforderlich. Aufgrund lokaler Vorschriften können jedoch einige Einschränkungen gelten.",
                        "Q3": "Wie weit im Voraus sollte ich buchen?",
                        "A3": "Wir empfehlen Ihnen, Ihren Bootsverleih mindestens eine Woche im Voraus zu buchen Sicherstellung der Verfügbarkeit, insbesondere in Spitzenzeiten.",
                        "Q4": "Werden beim Bootsverleih Schwimmwesten mitgeliefert?",
                        "A4": "Ja, wir stellen allen Passagieren Schwimmwesten zur Verfügung, da Sicherheit für uns oberste Priorität hat.",
                        "Q5": "Wie lauten Ihre Stornierungsbedingungen?",
                        "A5": "Die Stornierungsbedingungen variieren je nach Mietdauer und den Jahreszeiten."
                    },
                    "contact": {
                        "heading": "Kontaktieren Sie uns",
                        "subheading": "Nehmen Sie Kontakt auf",
                        "paragraph": "Wir würden uns freuen, von Ihnen zu hören. Bitte füllen Sie das Formular aus oder kontaktieren Sie uns über die angegebenen Kontaktinformationen.",
                        "address": {
                            "k": "Adresse",
                            "v": "Loutro Pteleou 37007, Griechenland"
                        },
                        "phone": "Kontakttelefon",
                        "form": {
                            "name": "Vollständiger Name",
                            "email": "Email",
                            "subject": "Betreff",
                            "message": "Nachricht",
                            "send": "Senden",
                            "error": {
                                "mandatory": "Das Feld ist obligatorisch",
                                "invalid_email": "Bitte geben Sie eine gültige E-Mail-Adresse ein"
                            }
                        }
                    }

                }
            },
            el: {
                translation: {
                    "nav": {
                        "home": "Αρχική",
                        "boats": "Σκάφη",
                        "destinations": "Προορισμοί",
                        "faq": "Συχνές Ερωτήσεις",
                        "contact": "Επικοινωνία",
                    },
                    "home": {
                        "welcome_message": "Ανακαλύψτε τον Παγασητικό μαζί μας!",
                        "welcome_description": "Με τις άρτια εξοπλισμένες λέμβους μας, οι επισκέπτες μπορούν να απολαύσουν μια ασφαλή και ευχάριστη εμπειρία στα κρυστάλλινα νερά του Αιγαίου, ενώ έχουν τη δυνατότητα να εξερευνήσουν την πλούσια θαλάσσια ζωή και τα ενδιαφέροντα αξιοθέατα της περιοχής. Ανακαλύψτε τη μαγεία του Πτελεού και της γύρω περιοχής μέσα από μια αξέχαστη εμπειρία λεμβοναύτη!",
                    },
                    "boats": {
                        "heading": "Ανακαλύψτε τα σκάφη μας",
                        "subheading": "Τι μας ξεχωρίζει από τους υπόλοιπους",
                        "info_box": {
                            "length": "Μήκος",
                            "people_capacity": "Χωρητικότητα Ατόμων",
                            "horsepower": "Ιπποδύναμη",
                            "licence_required": "Απαιτείται Άδεια"
                        },
                        "marios": {
                            "name": "Μάριος",
                            "length": "4,5μ",
                            "people_capacity": "6",
                            "horsepower": "30-40 HP",
                            "license_required": "Όχι",
                        },
                        "thanasis": {
                            "name": "Θανάσης",
                            "length": "5m",
                            "people_capacity": "7",
                            "horsepower": "30-50 HP",
                            "license_required": "Όχι",
                        },
                        "dimitra": {
                            "name": "Δήμητρα",
                            "length": "4,5m",
                            "people_capacity": "6",
                            "horsepower": "20 HP",
                            "license_required": "Όχι",
                        }
                        // "variety_of_boats": "Μεγάλη ποικιλία σκαφών",
                        // "choose_boat": "Επιλέξτε από μια ποικιλία σκαφών που ταιριάζουν στις ανάγκες σας",
                        // "fully_equipped": "Πλήρως εξοπλισμένα και καλά συντηρημένα σκάφη",
                        // "highest_standards": "Όλα τα σκάφη μας διατηρούνται σύμφωνα με τα αυστηρότερα πρότυπα",
                        // "exclusive_destinations": "Αποκλειστικοί Προορισμοί Εκδρομών",
                        // "explore_beauty": "Εξερευνήστε τις ομορφιές του Παγασητικού για μια αξέχαστη εμπειρία",
                        // "flexible_rentals": "Ευέλικτες επιλογές ενοικίασης",
                        // "customizable_packages": "Προσαρμόσιμα πακέτα ενοικίασης για να ταιριάζουν στο πρόγραμμα και τις προτιμήσεις σας",
                    },
                    "destinations": {
                        "heading": "Ανακαλύψτε την Ομορφιά της Θάλασσας",
                        "description": "Ξεκινήστε ένα αξέχαστο ταξίδι με τα κορυφαία σκάφη μας γύρω από τον Πτελεό, μια εξαιρετικά γραφική παραθαλάσσια περιοχή της Μαγνησίας. Με φόντο το Αιγαίο και το μαγευτικό βουνό του Πηλίου να υψώνεται μαγευτικά στο βάθος, οι επισκέπτες του Πτελεού απολαμβάνουν έναν μοναδικό συνδυασμό εμπειρίας βουνού και θάλασσας. Η περιοχή διαθέτει μια πληθώρα ενδιαφέροντων τοποθεσιών που μπορούν να εξερευνηθούν με λέμβο. Οι ενοικιαζόμενες λέμβοι θα σας επιτρέψουν να ανακαλύψετε τις κρυμμένες παραλίες, τους φυσικούς κόλπους και τις απόμερες γραφικές ακτές της περιοχής, προσφέροντας μια μοναδική ευκαιρία να απολαύσετε τον κόσμο του Αιγαίου από μια εντελώς διαφορετική οπτική γωνία. Με τον κατάλληλο εξοπλισμό θα απολαύσετε μια ασφαλή και ευχάριστη εμπειρία στα κρυστάλλινα νερά του Αιγαίου, ενώ θα έχετε την ευκαιρία να εξερευνήσετε την πλούσια θαλάσσια ζωή και τα ενδιαφέροντα αξιοθέατα. Ανακαλύψτε τη μαγεία του Πτελεού και της γύρω περιοχής!",
                        "captions": {
                            "Pteleos_Castle": {
                                "title": "Κάστρο Πτελεού",
                                "description": "Το Κάστρο του Πτελεού είναι μια αρχαία οχυρωμένη πόλη που χτίστηκε σε έναν λόφο πάνω από τον Πτελεό. Προσφέρει εκπληκτική θέα στον Παγασητικό Κόλπο, το Πήλιο και την ευρύτερη περιοχή.",
                            },
                            "Pigadi": {
                                "title": "Πηγάδι",
                                "description": "Το Πηγάδι είναι ένα πανέμορφο παραδοσιακό χωριό με πλούσια φυσική ομορφιά και παραδοσιακή αρχιτεκτονική. Οι επισκέπτες μπορούν να απολαύσουν την ηρεμία του τοπίου και να δοκιμάσουν τις τοπικές γεύσεις στις ταβέρνες του χωριού.",
                            },
                            "Palio_Trikeri": {
                                "title": "Παλαιό Τρίκερι",
                                "description": "Το παραδοσιακό χωριό Παλιό Τρίκερι βρίσκεται σε μικρή απόσταση από τον Πτελεό και προσφέρει εκπληκτική θέα στο Αιγαίο Πέλαγος. Οι επισκέπτες μπορούν να περιηγηθούν στα γραφικά σοκάκια και να απολαύσουν την αυθεντική ατμόσφαιρα του χωριού.",
                            },
                            "Agia_Kyriaki": {
                                "title": "Αγία Κυριάκη",
                                "description": "Η παραλία της Αγίας Κυριάκης προσφέρει έναν εκπληκτικό συνδυασμό αμμουδιάς και βραχώδους τοπίου, με κρυστάλλινα νερά που προσελκύουν λάτρεις της θαλάσσιας σπορ και του snorkeling.",
                            },
                            "Jasteni": {
                                "title": "Τζάστενη",
                                "description": "Ο διπλός κολπίσκος με τα γαλαζοπράσινα νερά, περιοχή προστατευόμενη από την Unesco."
                            }
                        },
                    },
                    "faq": {
                        "heading": "Συχνές Ερωτήσεις",
                        "subheading": "Εδώ είναι μερικές από τις πιο συνηθισμένες ερωτήσεις που λαμβάνουμε.",
                        "Q1": "Τι είδη σκαφών προσφέρετε για ενοικίαση;",
                        "A1": "Προσφέρουμε μια ποικιλία σκαφών, συμπεριλαμβανομένων ταχύπλοων και αλιευτικών.",
                        "Q2": "Χρειάζομαι δίπλωμα για να νοικιάσω σκάφος;",
                        "A2": "Στις περισσότερες περιπτώσεις, δεν απαιτείται δίπλωμα για τις ενοικιάσεις μας. Ωστόσο, ενδέχεται να ισχύουν ορισμένοι περιορισμοί βάσει των τοπικών κανονισμών.",
                        "Q3": "Πόσο νωρίτερα πρέπει να κάνω κράτηση;",
                        "A3": "Συνιστούμε να κάνετε κράτηση για την ενοικίαση του σκάφους σας τουλάχιστον μία εβδομάδα νωρίτερα για να εξασφαλίσετε τη διαθεσιμότητα, ειδικά κατά τις περιόδους αιχμής.",
                        "Q4": "Παρέχονται σωσίβια με την ενοικίαση του σκάφους;",
                        "A4": "Ναι, παρέχουμε σωσίβια για όλους τους επιβάτες καθώς η ασφάλεια είναι η κορυφαία μας προτεραιότητα.",
                        "Q5": "Ποια είναι η πολιτική ακύρωσης σας;",
                        "A5": "Η πολιτική ακύρωσης ποικίλλει ανάλογα με τη διάρκεια της ενοικίασης και τις εποχές."
                    },
                    "contact": {
                        "heading": "Επικοινωνήστε μαζί μας",
                        "subheading": "Ελάτε σε επαφή",
                        "paragraph": "Θα χαρούμε να σας ακούσουμε. Παρακαλούμε συμπληρώστε τη φόρμα ή επικοινωνήστε μαζί μας χρησιμοποιώντας τα στοιχεία επικοινωνίας που παρέχονται.",
                        "address": {
                            "k": "Διεύθυνση",
                            "v": "Λουτρό Πτελεού 37007, Ελλάδα"
                        },
                        "phone": "Τηλ. Επικοινωνίας",
                        "form": {
                            "name": "Όνοματεπώνυμο",
                            "email": "Email",
                            "subject": "Θέμα",
                            "message": "Μήνυμα",
                            "send": "Αποστολή",
                            "error": {
                                "mandatory": "Το πεδίο είναι υποχρεωτικό",
                                "invalid_email": "Παρακαλώ εισάγετε μια έγκυρη διεύθυνση email"
                            }
                        }
                    }
                }
            }
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
