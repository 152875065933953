// src/components/NavLink.js
import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import logo from "../../assets/logo/logo_simple.svg";

export const LogoNavLink = ({to, label, imageUrl, onClick}) => {
    const {t} = useTranslation();

    return (
        <a href={to} onClick={onClick} className="flex items-center space-x-2">
            <img src={logo} alt="Logo" className="w-auto h-30 lg:h-32 py-4"/>
        </a>
    );
}

const NavLink = ({to, label, onClick}) => {
    const {t} = useTranslation();

    return (
        <a href={to} onClick={onClick}
           className="text-xl 2xl:text-2xl text-gray-800 mx-2 px-3 py-2 rounded-md font-semibold transition duration-200 ease-in-out hover:text-blue-600">
            {t(label)}
        </a>
    );
};

NavLink.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default NavLink;
