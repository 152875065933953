import React from 'react';
import {useTranslation} from 'react-i18next';

const BoatItem = ({ boat }) => {
    const { t } = useTranslation();

    return (
        <div className="relative boat-slide flex flex-col items-start justify-center  px-8 md:px-0">
            <h2 className="text-3xl md:text-5xl uppercase text-white">{boat.name}</h2>
            <div className="relative w-full flex items-center justify-center">
                <img src={boat.imageUrl} alt={boat.name} className="w-full h-auto md:h-[65vh] object-cover" />
                <div className="hidden md:block absolute bottom-4 left-4 bg-black bg-opacity-70 text-white p-6 rounded-lg space-y-2">
                    <p className="text-lg"><strong>{t('boats.info_box.length')}:</strong> {boat.length}</p>
                    <p className="text-lg"><strong>{t('boats.info_box.people_capacity')}:</strong> {boat.peopleCapacity}</p>
                    <p className="text-lg"><strong>{t('boats.info_box.horsepower')}:</strong> {boat.horsepower}</p>
                    <p className="text-lg"><strong>{t('boats.info_box.licence_required')}:</strong> {boat.licenseRequired}</p>
                </div>
            </div>
            <div className="block md:hidden w-full bg-white p-6 rounded-lg space-y-2">
                <p className="text-lg"><strong>{t('boats.info_box.length')}:</strong> {boat.length}</p>
                <p className="text-lg"><strong>{t('boats.info_box.people_capacity')}:</strong> {boat.peopleCapacity}</p>
                <p className="text-lg"><strong>{t('boats.info_box.horsepower')}:</strong> {boat.horsepower}</p>
                <p className="text-lg"><strong>{t('boats.info_box.licence_required')}:</strong> {boat.licenseRequired}</p>
            </div>
        </div>
    );
};


export default BoatItem;
