import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo/logo_larger_slogan.svg';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="bg-white pb-8">
            <div className="container mx-auto px-8">
                <hr className="border-gray-300 mb-4"/>
                <div className="flex flex-col items-center text-center space-y-8">
                    <img src={logo} alt="Pantoporos Ltd" className="w-48"/>
                    <div className="text-gray-700 text-xl space-y-0.5">
                        <p>{t('contact.address.v')}</p>
                        <p>8 AM – 9 PM</p>
                        <p>(+30) 6909140805</p>
                        <p>pantoporos.ltd(at)gmail.com</p>
                        <p>&copy; 2024 Pantoporos Ltd, All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
