import React from 'react';
import {useTranslation} from 'react-i18next';
import {Accordion, AccordionBody, AccordionHeader} from "@material-tailwind/react";

function Icon({id, open}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
        </svg>
    );
}

const FAQ = () => {
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(0);

    const handleOpen = (value) => setOpen(open === value ? 0 : value);

    const faqs = [
        {
            question: t('faq.Q1'),
            answer: t('faq.A1')
        },
        {
            question: t('faq.Q2'),
            answer: t('faq.A2')
        },
        {
            question: t('faq.Q3'),
            answer: t('faq.A3')
        },
        {
            question: t('faq.Q4'),
            answer: t('faq.A4')
        },
        {
            question: t('faq.Q5'),
            answer: t('faq.A5')
        }
    ];

    return (
        <section id="faq" className="home-faq bg-[#02B2F7] w-full flex flex-col items-center px-16 pt-8 pb-24 ">
            <h1 className="text-3xl lg:text-5xl text-white mb-8">
                {t('faq.heading')}
            </h1>
            <h2 className="text-2xl lg:text-xl text-white text-center mb-8">
                {t('faq.subheading')}
            </h2>
            <div className="w-full max-w-4xl mx-auto px-4">

                {faqs.map((faq, index) => (
                    <Accordion
                        key={index}
                        open={open === index + 1}
                        icon={<Icon id={index + 1} open={open}/>}
                    >
                        <AccordionHeader
                            className="text-white hover:text-gray-300 "
                            onClick={() => handleOpen(index + 1)}>{faq.question}
                        </AccordionHeader>
                        <AccordionBody className="text-xl text-white py-8">
                            {faq.answer}
                        </AccordionBody>
                    </Accordion>
                ))}
            </div>
        </section>
    );
};

export default FAQ;
