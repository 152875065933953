import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactInfo = () => {
    const { t } = useTranslation();

    return (
        <div className="flex-1 mb-8 lg:mb-0 ">
            <h2 className="text-2xl lg:text-3xl text-center md:text-left mb-4">{t('contact.subheading')}</h2>
            <p className="text-lg lg:text-xl text-gray-700 mb-4">{t('contact.paragraph')}</p>
            <div>
                <p className="text-lg"><strong>{t('contact.address.k')}: </strong>{t('contact.address.v')}</p>
                <p className="text-lg"><strong>{t('contact.phone')}:</strong> (+30) 6909140805</p>
                <p className="text-lg"><strong>Email:</strong> pantoporos.ltd(at)gmail.com</p>
            </div>
            <div className="mt-8">
                <iframe
                    title="Pantoporos Location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5213.750687750916!2d22.961771012937!3d39.033662771577184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a749dcf963fe19%3A0xcd583257683a73fe!2sPantoporos%20Ltd!5e1!3m2!1sen!2sgr!4v1716044780091!5m2!1sen!2sgr"
                    width="100%"
                    height="400"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>
        </div>
    );
};

export default ContactInfo;
