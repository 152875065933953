import React from 'react';
import { useTranslation } from 'react-i18next';
import image from '../../assets/top.jpg';

function Home({ navbarHeight }) {
  const { t } = useTranslation();

  return (
    <section
      id="home"
      className="relative h-screen bg-gray-100 flex items-center justify-center overflow-hidden px-4 sm:px-8 pt-8"
    >
      <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: `url(${image})` }} />
      <div className="absolute inset-0 bg-black opacity-50 "></div>
      <div className="relative z-10 text-center flex flex-col justify-start items-center w-full space-y-24" style={{ marginTop: `-${navbarHeight}px` }}>
        <h1 className="text-white font-bold mt-[-200px] text-3xl sm:text-5xl xl:text-7xl">
          {t('home.welcome_message')}
        </h1>
        <p className="text-white font-medium leading-relaxed md:leading-relaxed xl:leading-relaxed mt-4 text-base sm:text-xl xl:text-2xl max-w-3xl mx-auto">
          {t('home.welcome_description')}
        </p>
      </div>
    </section>
  );
}

export default Home;
