import React, { forwardRef, useState } from 'react';
import LanguageSelector from '../LanguageSelector';
import logo from '../../assets/logo/logo_simple.svg';
import NavLink, {LogoNavLink} from './NavLink';
import { Menu, MenuHandler, MenuList, MenuItem } from "@material-tailwind/react";

const Navbar = forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <nav
                ref={ref}
                className="sticky top-0 bg-white text-gray-800 flex justify-between items-center shadow-md p-4 lg:p-0 z-50"
            >
                {/* Mobile Menu Button */}
                <div className="flex items-center lg:hidden px-6">
                    <Menu open={isOpen} handler={setIsOpen} placement="bottom-start">
                        <MenuHandler>
                            <button onClick={toggleMenu} className="text-gray-800 focus:outline-none">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                                </svg>
                            </button>
                        </MenuHandler>
                        <MenuList className={`absolute left-0 right-0 bg-white shadow-md ${isOpen ? 'flex' : 'hidden'} flex-col space-y-4 p-4 z-20 justify-center items-center mt-16`}>
                            <MenuItem className="w-full text-center"><NavLink to="#home" label="nav.home" onClick={() => setIsOpen(false)} /></MenuItem>
                            <MenuItem className="w-full text-center"><NavLink to="#boats" label="nav.boats" onClick={() => setIsOpen(false)} /></MenuItem>
                            <MenuItem className="w-full text-center"><NavLink to="#destinations" label="nav.destinations" onClick={() => setIsOpen(false)} /></MenuItem>
                            <MenuItem className="w-full text-center"><NavLink to="#faq" label="nav.faq" onClick={() => setIsOpen(false)} /></MenuItem>
                            <MenuItem className="w-full text-center"><NavLink to="#contact" label="nav.contact" onClick={() => setIsOpen(false)} /></MenuItem>
                            {/* Language Selector for Mobile */}
                            <MenuItem className="p-0 mt-4"><LanguageSelector /></MenuItem>
                        </MenuList>
                    </Menu>
                </div>
                {/* Logo */}
                <div className="flex-1 flex lg:flex-none justify-center items-center pr-12 lg:pl-32">
                    {/*<button onClick={() => window.location.href = '#home'} className="focus:outline-none">*/}
                    {/*    <img src={logo} alt="Logo" className="w-auto h-30 lg:h-32 py-4" />*/}
                    {/*</button>*/}
                    <LogoNavLink to={'#home'} imageUrl={logo} />
                </div>
                {/* Nav Links for Larger Screens */}
                <div className="hidden lg:flex lg:flex-1 lg:justify-center lg:space-x-8">
                    <NavLink to="#home" label="nav.home" />
                    <NavLink to="#boats" label="nav.boats" />
                    <NavLink to="#destinations" label="nav.destinations" />
                    <NavLink to="#faq" label="nav.faq" />
                    <NavLink to="#contact" label="nav.contact" />
                </div>
                {/* Language Selector for Larger Screens */}
                <div className="hidden lg:flex items-center pr-4 lg:pr-8">
                    <LanguageSelector />
                </div>
            </nav>
        </>
    );
});

export default Navbar;
