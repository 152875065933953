import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactInfo from "./ContactInfo";
import ContactForm from "./ContactForm";

const Contact = () => {
    const { t } = useTranslation();

    return (
        <section id="contact" className="w-full px-16 pt-8 pb-24 ">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-3xl sm:text-5xl mb-16 text-center">
                    {t('contact.heading')}
                </h1>
                <div className="flex flex-col lg:flex-row lg:space-x-16">
                    <ContactInfo />
                    <div className="flex-1 mt-8 lg:mt-0">
                        <ContactForm />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
