import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import Navbar from './components/navbar/Navbar';
import Home from './components/home/Home';
import Destinations from './components/destinations/Destinations';
import FAQ from './components/faq/FAQ';
import Contact from './components/contact/Contact';
import SmoothScroll from 'smooth-scroll';
import Footer from "./components/footer/Footer";
import Boats from "./components/boats/Boats";

function App() {
    const navbarRef = useRef(null);
    const [navbarHeight, setNavbarHeight] = useState(0);

    useLayoutEffect(() => {
        const updateNavbarHeight = () => {
            if (navbarRef.current) {
                setNavbarHeight(navbarRef.current.getBoundingClientRect().height);
            }
        };

        // Initial update
        updateNavbarHeight();

        // Update on window resize
        window.addEventListener('resize', updateNavbarHeight);

        // Cleanup event listener
        return () => {
            window.removeEventListener('resize', updateNavbarHeight);
        };
    }, []);

    useEffect(() => {
        if (navbarHeight > 0) {
            const scroll = new SmoothScroll('a[href*="#"]', {
                speed: 800,
                offset: navbarHeight,
            });

            // Cleanup SmoothScroll instance on component unmount
            return () => scroll.destroy();
        }
    }, [navbarHeight]);

    return (
        <div>
            <Navbar ref={navbarRef} />
            <main>
                <Home navbarHeight={navbarHeight} />
                <Boats />
                <Destinations />
                <FAQ />
                <Contact />
            </main>
            <Footer/>
        </div>
    );
}

export default App;
