import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Carousel, IconButton} from "@material-tailwind/react";
import PropTypes from "prop-types";
import ag_kyriaki from '../../assets/ag_kyriaki_upscaled.jpeg';
import castle from '../../assets/castle.jpeg';
import pigadi from '../../assets/pigadi.jpg';
import trikeri from '../../assets/trikeri.jpg';
import tzasteni from '../../assets/tzasteni-κεντρικη.jpg';

function DestinationsContent() {
    const {t} = useTranslation();
    return (
        <div className="flex-1 lg:pr-12 mb-8 text-center lg:mb-0">
            <p className="text-base lg:leading-loose lg:text-xl text-center text-gray-700 ">
                {t('destinations.description')}
            </p>
        </div>
    );
}

function DestinationsCarousel({children}) {
    return (
        <div className="relative w-full mx-auto">
            <Carousel
                autoplay={true}
                loop
                autoplayDelay={10000}
                className="rounded-lg overflow-hidden h-[600px]"
                navigation={({setActiveIndex, activeIndex, length}) => (
                    <div className="absolute bottom-4 left-2/4 z-40 flex -translate-x-2/4 gap-2">
                        {new Array(length).fill("").map((_, i) => (
                            <span
                                key={i}
                                className={`block h-1 cursor-pointer rounded-2xl transition-all ${activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"}`}
                                onClick={() => setActiveIndex(i)}
                            />
                        ))}
                    </div>
                )}
                prevArrow={({handlePrev}) => (
                    <IconButton
                        variant="text"
                        color="white"
                        size="lg"
                        onClick={handlePrev}
                        className="!absolute top-1/2 left-4"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-6 w-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                            />
                        </svg>
                    </IconButton>
                )}
                nextArrow={({handleNext}) => (
                    <IconButton
                        variant="text"
                        color="white"
                        size="lg"
                        onClick={handleNext}
                        className="!absolute top-1/2 !right-4"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-6 w-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                            />
                        </svg>
                    </IconButton>
                )}
            >
                {children}
            </Carousel>
        </div>
    );
}

DestinationsCarousel.propTypes = {
    children: PropTypes.node.isRequired,
};

function Destinations() {
    const {t} = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // const images = [destination1, destination2, destination3, destination4, destination5];
    const destinations = [
        {
            title: t('destinations.captions.Jasteni.title'),
            description: t('destinations.captions.Jasteni.description'),
            image: tzasteni,
        },
        {
            title: t('destinations.captions.Palio_Trikeri.title'),
            description: t('destinations.captions.Palio_Trikeri.description'),
            image: trikeri,
        },
        {
            title: t('destinations.captions.Pteleos_Castle.title'),
            description: t('destinations.captions.Pteleos_Castle.description'),
            image: castle,
        },
        {
            title: t('destinations.captions.Agia_Kyriaki.title'),
            description: t('destinations.captions.Agia_Kyriaki.description'),
            image: ag_kyriaki,
        },
        {
            title: t('destinations.captions.Pigadi.title'),
            description: t('destinations.captions.Pigadi.description'),
            image: pigadi,
        },


    ];

    const openModal = (image) => {
        setSelectedImage(image);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setIsModalOpen(false);
    };

    return (
        <section id="destinations"
                 className="px-6 md:px-16 pt-8 pb-24 flex flex-col items-center justify-center font-sans w-full">
            <h1 className="text-3xl lg:text-5xl mb-16">
                {t('destinations.heading')}
            </h1>
            <div className="flex flex-col lg:flex-row lg:space-x-12 w-full">
                <DestinationsContent/>
                <div className="flex-1">
                    <DestinationsCarousel>
                        {destinations.map((image, index) => (
                            <div key={index} className="relative w-full h-[600px] cursor-pointer"
                                 onClick={() => openModal(image)}>
                                <img src={destinations[index].image} alt={`Slide ${index}`}
                                     className="object-cover w-full h-full"/>
                                <div
                                    className="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-black via-transparent to-transparent p-4">
                                    <p className="text-lg lg:text-xl font-semibold text-white">{destinations[index].title}</p>
                                    <p className="text-sm lg:text-base text-white mt-1 mb-4">{destinations[index].description}</p>
                                </div>
                            </div>
                        ))}
                    </DestinationsCarousel>
                </div>
            </div>


            {/*{isModalOpen && (*/}
            {/*    <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-90 z-50"*/}
            {/*         onClick={closeModal}>*/}
            {/*        <div className="relative rounded-lg  bg-white max-w-[75vw] max-h-[75vh] mx-auto"*/}
            {/*             onClick={(e) => e.stopPropagation()}>*/}
            {/*            <button className="absolute top-2 right-2 text-gray-800 p-2" onClick={closeModal}>*/}
            {/*                <FontAwesomeIcon icon={faTimes} size="2x"/>*/}
            {/*            </button>*/}
            {/*            <Carousel*/}
            {/*                className="rounded-lg overflow-hidden h-[75vh]"*/}
            {/*                index={selectedIndex}*/}
            {/*                navigation={({setActiveIndex, activeIndex, length}) => (*/}
            {/*                    <div className="absolute bottom-4 left-2/4 z-40 flex -translate-x-2/4 gap-2">*/}
            {/*                        {new Array(length).fill("").map((_, i) => (*/}
            {/*                            <span*/}
            {/*                                key={i}*/}
            {/*                                className={`block h-1 cursor-pointer rounded-2xl transition-all ${activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"}`}*/}
            {/*                                onClick={() => setActiveIndex(i)}*/}
            {/*                            />*/}
            {/*                        ))}*/}
            {/*                    </div>*/}
            {/*                )}*/}

            {/*            >*/}
            {/*                {images.map((image, index) => (*/}
            {/*                    <div key={index} className="relative w-full h-[75vh]">*/}
            {/*                        <img src={image} alt={`Slide ${index}`} className="object-cover w-full"/>*/}

            {/*                    </div>*/}
            {/*                ))}*/}
            {/*            </Carousel>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}

        </section>
    );
}

export default Destinations;
