import React from 'react';
import {Carousel, IconButton} from '@material-tailwind/react';
import {useTranslation} from 'react-i18next';
import marios from '../../assets/boats/Marios.jpg';
import dimitra from '../../assets/boats/Dimitra.jpg';
import thanasis from '../../assets/boats/Thanasis.jpg';
import BoatItem from "./Boat";

const Boats = () => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = React.useState(0);
    const boatData = [
        {
            name: t('boats.marios.name'),
            length: t('boats.marios.length'),
            peopleCapacity: t('boats.marios.people_capacity'),
            horsepower: t('boats.marios.horsepower'),
            licenseRequired: t('boats.marios.license_required'),
            imageUrl: marios,
        },
        {
            name: t('boats.thanasis.name'),
            length: t('boats.thanasis.length'),
            peopleCapacity: t('boats.thanasis.people_capacity'),
            horsepower: t('boats.thanasis.horsepower'),
            licenseRequired: t('boats.thanasis.license_required'),
            imageUrl: thanasis,
        },
        {
            name: t('boats.dimitra.name'),
            length: t('boats.dimitra.length'),
            peopleCapacity: t('boats.dimitra.people_capacity'),
            horsepower: t('boats.dimitra.horsepower'),
            licenseRequired: t('boats.dimitra.license_required'),
            imageUrl: dimitra,
        },
    ];

    return (
        <section
            id="boats"
            className="px-4 md:px-16 pt-8 pb-24 flex flex-col items-center justify-center font-sans w-full"
        >
            <div className="max-w-7xl mx-auto flex flex-col items-center justify-center">
                <h1 className="home-features-heading text-3xl lg:text-5xl mb-8">
                    {t('boats.heading')}
                </h1>
                <div className="relative w-full mx-auto overflow-hidden">
                    <Carousel
                        autoplay={true}
                        loop
                        interval={10000}
                        className="boat-carousel flex"
                        animation="fade"
                        navigation={({ setActiveIndex, activeIndex, length }) => (
                            <div className="absolute bottom-4 left-2/4 z-40 flex -translate-x-2/4 gap-2">
                                {new Array(length).fill("").map((_, i) => (
                                    <span
                                        key={i}
                                        className={`block h-1 cursor-pointer rounded-2xl transition-all ${activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"}`}
                                        onClick={() => setActiveIndex(i)}
                                    />
                                ))}
                            </div>
                        )}
                        prevArrow={({ handlePrev }) => (
                            <IconButton
                                variant="text"
                                color="white"
                                size="lg"
                                onClick={handlePrev}
                                className="!absolute top-1/2 left-10"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                                    />
                                </svg>
                            </IconButton>
                        )}
                        nextArrow={({ handleNext }) => (
                            <IconButton
                                variant="text"
                                color="white"
                                size="lg"
                                onClick={handleNext}
                                className="!absolute top-1/2 !right-10"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                    />
                                </svg>
                            </IconButton>
                        )}
                        indicators={false}
                        onChange={(index) => setActiveIndex(index)}
                    >
                        {boatData.map((boat, index) => (
                            <BoatItem key={index} boat={boat} />
                        ))}
                    </Carousel>
                </div>
            </div>
        </section>
    );
};

export default Boats;
