import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
    const { t } = useTranslation();
    const [form, setForm] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.name = form.name ? "" : t('contact.form.error.mandatory');
        tempErrors.email = (/$^|.+@.+..+/).test(form.email) ? "" : t('contact.form.error.invalid_email');
        tempErrors.subject = form.subject ? "" : t('contact.form.error.mandatory');
        tempErrors.message = form.message ? "" : t('contact.form.error.mandatory');
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            console.log('Form submitted successfully:', form);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6 flex flex-col justify-between h-full">
            <div>
                <label className="block text-lg font-medium text-gray-700" htmlFor="name">{t('contact.form.name')}</label>
                <input
                    className={`w-full mt-1 border-b-2 focus:outline-none focus:border-primary-100 ${errors.name ? 'border-red-500' : 'border-gray-300'} transition duration-200`}
                    type="text"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                    required
                />
                {errors.name && <span className="text-red-500 text-xs mt-1">{errors.name}</span>}
            </div>
            <div>
                <label className="block text-lg font-medium text-gray-700" htmlFor="email">{t('contact.form.email')}</label>
                <input
                    className={`w-full mt-1 border-b-2 focus:outline-none focus:border-primary-100 ${errors.email ? 'border-red-500' : 'border-gray-300'} transition duration-200`}
                    type="email"
                    name="email"
                    value={form.email}
                    onChange={handleChange}
                    required
                />
                {errors.email && <span className="text-red-500 text-xs mt-1">{errors.email}</span>}
            </div>
            <div>
                <label className="block text-lg font-medium text-gray-700" htmlFor="subject">{t('contact.form.subject')}</label>
                <input
                    className={`w-full mt-1 border-b-2 focus:outline-none focus:border-primary-100 ${errors.subject ? 'border-red-500' : 'border-gray-300'} transition duration-200`}
                    type="text"
                    name="subject"
                    value={form.subject}
                    onChange={handleChange}
                    required
                />
                {errors.subject && <span className="text-red-500 text-xs mt-1">{errors.subject}</span>}
            </div>
            <div>
                <label className="block text-lg font-medium text-gray-700" htmlFor="message">{t('contact.form.message')}</label>
                <textarea
                    className={`w-full mt-1 border-b-2 focus:outline-none focus:border-primary-100 ${errors.message ? 'border-red-500' : 'border-gray-300'} transition duration-200`}
                    name="message"
                    rows="4"
                    value={form.message}
                    onChange={handleChange}
                    required
                ></textarea>
                {errors.message && <span className="text-red-500 text-xs mt-1">{errors.message}</span>}
            </div>
            <div>
                <button
                    type="submit"
                    className="w-full p-3 bg-[#58B7F5] text-white text-bold rounded-lg hover:bg-primary-200 transition duration-300"
                >
                    {t('contact.form.send')}
                </button>
            </div>
        </form>
    );
};

export default ContactForm;
